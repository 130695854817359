import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { type RemarkByApartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

import { HeadCell } from '../../../components/table/type'
import { RemarksTable } from '../components'
import { RemarkNewContext } from '../contexts'
import { Style } from '../utils'

const useCellApartment = (houseId: number) => {
  const { isSpecialistRight, isAdminRight } = useRightCurrentUser()
  const { setDetailedRemarkByApartment } = useContextSafe(RemarkNewContext)

  const hasCreateRemark = isSpecialistRight || isAdminRight

  const headCells: HeadCell<RemarkByApartment>[] = [
    {
      id: 'typesOfWork',
      numeric: false,
      disablePadding: false,
      isSort: true,
      label: 'Основные виды работ',
      style: { ...Style, width: '200px' },
      align: 'left',
      render: (typesOfWork, remarkByApartment) => (
        <Grid container direction="column" alignItems="start">
          {typesOfWork}
          {hasCreateRemark && (
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              size="small"
              sx={{ mt: 1 }}
              onClick={() => setDetailedRemarkByApartment(remarkByApartment)}
              aria-label="add"
            >
              <Add />
            </Button>
          )}
        </Grid>
      )
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      isSort: false,
      align: 'left',
      label: 'Информация',
      style: { ...Style, maxWidth: '100%' },
      render: (_, remarkByApartment) => {
        const { remarks = [] } = remarkByApartment

        if (!remarks.length) {
          return <></>
        }

        return <RemarksTable houseId={houseId} remarks={remarks} />
      }
    }
  ]

  return headCells
}

export { useCellApartment }
