import { createContext, ReactElement, useMemo, useState } from 'react'

import { RemarkByApartment, RemarksByApartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'

type ApartmentProps = {
  apartmentId: number
  houseId: number
}

type RemarkContextState = {
  remarksByApartment: RemarksByApartment[] | null
  setParamsContext: (apartmentProps: ApartmentProps) => void
  paramsContext: ApartmentProps
  currentState: RemarkByApartment | null
  setCurrentState: (currentState: RemarkByApartment | null) => void
}

type Props = {
  children: ReactElement
}

const RemarkContext = createContext<RemarkContextState | null>(null)

const RemarkContextProvider = ({ children }: Props) => {
  const visibilityRemark = localStorage.getItem('visibilityRemark') ?? '0'
  const { isExpertRight, user } = useRightCurrentUser()
  const [paramsContext, setParamsContext] = useState<ApartmentProps>({ apartmentId: 0, houseId: 0 })
  const [currentState, setCurrentState] = useState<RemarkByApartment | null>(null)

  const remarks = useAppSelector((state) => state.houseState.remarksByApartment)

  const remarksByApartment = useMemo(() => {
    if (!isExpertRight) {
      return remarks
    }

    if (visibilityRemark === '1') {
      return remarks
    }

    return (remarks ?? []).filter((remark) => remark.user_id === user?.id)
  }, [remarks])

  const value = useMemo(
    () => ({
      remarksByApartment,
      paramsContext,
      setParamsContext,
      currentState,
      setCurrentState
    }),
    [remarksByApartment, paramsContext, currentState]
  )

  return <RemarkContext.Provider value={value}>{children}</RemarkContext.Provider>
}

export { RemarkContext, RemarkContextProvider, type ApartmentProps }
