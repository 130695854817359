import { zodResolver } from '@hookform/resolvers/zod'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Drawer, IconButton } from '@mui/material/'
import { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useCreateHouseMutation, useHousesMutation } from '#api/house-api'
import { FormInput } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { HouseContext } from '../../context'
import { DrawerHeader } from '../../style'
import { type CreateHouseInput, createHouseSchema } from '../../types'

const SidebarHouse = () => {
  const { setIsOpen, isOpen } = useContextSafe(HouseContext)

  const [getHouses] = useHousesMutation()

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsOpen(newOpen)
  }

  const methods = useForm<CreateHouseInput>({
    resolver: zodResolver(createHouseSchema)
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  const [createHouse, { isLoading, isSuccess }] = useCreateHouseMutation()

  useEffect(() => {
    if (isSuccess) {
      toast.success('Дом успешно зарегистрирован')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<CreateHouseInput> = async (values) => {
    await createHouse(values)
    getHouses(null)
  }

  const DrawerList = (
    <Box sx={{ width: '600px' }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            p: { xs: '1rem', sm: '2rem' }
          }}
        >
          <FormInput name="street" label="Улица *" />
          <FormInput name="number" type="number" label="Номер *" />
          <FormInput name="drop" label="Корпус" />
          <FormInput name="count_apartments" label="Введите кол-во квартир *" type="number" />
          <FormInput name="count_office" label="Введите кол-во офисов *" type="number" />
          <FormInput name="count_storage" label="Введите кол-во кладовок *" type="number" />
          <FormInput name="count_parking" label="Введите кол-во гаражей *" type="number" />
          <FormInput name="developer" label="Застройщик *" />
          <FormInput name="director" label="Директор *" />

          <LoadingButton
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            disableElevation
            type="submit"
            loading={isLoading}
          >
            Создать
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  )

  return (
    <div>
      <Drawer open={isOpen} role="presentation" variant="persistent" anchor="right">
        <DrawerHeader>
          <IconButton onClick={toggleDrawer(false)}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { SidebarHouse }
