import { House } from '#api/types'
import { LayoutPage, Table } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { SidebarHouse, UpdateHouseSidebar } from './components'
import { HouseContextProvider, UpdateContext, UpdateContextProvider } from './context'
import { useHouseDataTable, useOptionsTable } from './hooks'

const HousePage = () => {
  const { updateHouse } = useContextSafe(UpdateContext)
  const { houses, headCells } = useHouseDataTable()
  const options = useOptionsTable()

  return (
    <LayoutPage title="Дома" {...options}>
      <>
        <Table
          isSelect={false}
          hasTableToolbar={false}
          propKey="id"
          defaultOrder="street"
          perPage={10}
          hasDetailPagination={true}
          tablePage={1}
          headCells={headCells}
          dataSource={houses as House[]}
        />

        <SidebarHouse />
        {updateHouse && <UpdateHouseSidebar />}
      </>
    </LayoutPage>
  )
}

const HousePageWithContext = () => (
  <UpdateContextProvider>
    <HouseContextProvider>
      <HousePage />
    </HouseContextProvider>
  </UpdateContextProvider>
)

export default HousePageWithContext
