import { Stack } from '@mui/material'

import { Apartment } from '#api/types'

import { ApartmentDocument, DeleteDocument, RemarkPrintBtn, SendDocument } from '../../components'

type Props = {
  apartment: Apartment
  title: string
  fullAddress: string
}

const ApartmentTableHeader = ({ title, fullAddress, apartment }: Props) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
    <span>
      {`${fullAddress} (${title})`}

      <ApartmentDocument apartment={apartment} />
      <DeleteDocument apartment={apartment} />
      <SendDocument apartment={apartment} />
    </span>
    <RemarkPrintBtn apartment={apartment} />
  </Stack>
)

export { ApartmentTableHeader }
