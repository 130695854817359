import { PhotoAlbum } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Link } from '@mui/material'
import { toast } from 'react-toastify'

import { useDestroyApartmentAcceptanceMutation } from '#api/note-api'
import { RemarkByApartment, RemarksByApartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

import { RemarkContext, RemarksAllViewContext } from '../../contexts'

type Props = {
  hasDetailTable: boolean
  remarkName: string
  item: RemarkByApartment
  setCurrentRemark: (remark: RemarksByApartment) => void
}

const RemarkName = ({ remarkName, item, hasDetailTable, setCurrentRemark }: Props) => {
  const [destroyApartmentAcceptance] = useDestroyApartmentAcceptanceMutation()

  const { setCurrentRemarks, setIsOpenAllRemarks, setCurrentType, fetchActualRemarks } =
    useContextSafe(RemarksAllViewContext)
  const { isAdminRight } = useRightCurrentUser()
  const { paramsContext } = useContextSafe(RemarkContext)
  const { houseId } = paramsContext

  const { remarks = [], relevantRemark } = item

  if (!remarkName) {
    return null
  }

  const onShowRemarks = () => {
    setCurrentRemarks(remarks)
    setCurrentType(item.typesOfWork ?? '')
    setIsOpenAllRemarks(true)
  }

  const onDestroy = async () => {
    const request = await destroyApartmentAcceptance({
      id: relevantRemark.id,
      houseId
    })

    if ('data' in request) {
      fetchActualRemarks(relevantRemark, paramsContext)
    } else {
      return toast.error('Что-то пошло не так повторите запрос позднее', {
        position: 'top-right'
      })
    }
  }

  return (
    <>
      {hasDetailTable && <p>{remarkName}</p>}

      {!hasDetailTable &&
        remarks.map((remark) => (
          <p
            key={remark.id}
            style={{ border: `${remark.id === relevantRemark.id ? 1 : 0}px solid black`, padding: '5px' }}
          >
            <Link sx={{ mr: 1 }}>
              <b>№{remark.id}</b>
            </Link>
            {remark?.remark?.name ?? ''}
          </p>
        ))}
      {!hasDetailTable && remarks.length > 1 && <Link onClick={onShowRemarks}>Подробнее</Link>}

      {hasDetailTable && (
        <>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setCurrentRemark(relevantRemark)}
            aria-label="add"
          >
            <PhotoAlbum sx={{ mr: 0.5 }} />
            Подробнее
          </Button>

          {isAdminRight && (
            <Button
              onClick={() => onDestroy()}
              type="submit"
              color="error"
              variant="outlined"
              size="small"
              aria-label="delete"
              sx={{ ml: 1 }}
            >
              <DeleteIcon />
            </Button>
          )}
        </>
      )}
    </>
  )
}

export { RemarkName }
