import AddHome from '@mui/icons-material/AddHome'
import { Button } from '@mui/material'

import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

import { HouseContext } from '../context'

const useOptionsTable = () => {
  const { setIsOpen } = useContextSafe(HouseContext)
  const { isAdminRight, isSpecialistRight } = useRightCurrentUser()

  if (isAdminRight || isSpecialistRight) {
    return {
      afterTitleElement: (
        <Button sx={{ maxWidth: '300px' }} startIcon={<AddHome />} variant="contained" onClick={() => setIsOpen(true)}>
          Создать дом
        </Button>
      )
    }
  }

  return {}
}

export { useOptionsTable }
