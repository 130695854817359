import { useParams } from 'react-router-dom'

import { Apartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { generateUrl } from '#utils'

type Props = {
  apartment: Apartment
}

const ApartmentDocument = ({ apartment }: Props) => {
  const params = useParams()
  const { isExpertRight } = useRightCurrentUser()

  if (isExpertRight || !apartment?.document) {
    return null
  }

  const [_, format] = apartment.document.split('.')

  const downloadDocument = () => {
    window.open(generateUrl(`remark/${params.houseId}/documents/${apartment?.document}`), '_blank')
  }

  return (
    <small
      style={{ borderBottom: '1px solid black', cursor: 'pointer', marginLeft: '10px' }}
      onClick={downloadDocument}
    >
      Акт.{format}
    </small>
  )
}

export { ApartmentDocument }
