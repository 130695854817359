import { createContext, ReactElement, useMemo, useState } from 'react'

import { RemarksByApartment } from '#api/types'

type SelectRemarkContextState = {
  selectRemark: RemarksByApartment | null
  setSelectRemark: (remark: RemarksByApartment | null) => void
}

type Props = {
  children: ReactElement
}

const SelectRemarkContext = createContext<SelectRemarkContextState | null>(null)

const SelectRemarkContextProvider = ({ children }: Props) => {
  const [selectRemark, setSelectRemark] = useState<RemarksByApartment | null>(null)

  const value = useMemo(
    () => ({
      selectRemark,
      setSelectRemark
    }),
    [selectRemark]
  )

  return <SelectRemarkContext.Provider value={value}>{children}</SelectRemarkContext.Provider>
}

export { SelectRemarkContext, SelectRemarkContextProvider }
