import { ApartmentsAcceptanceType, House, RemarkByApartment, RemarksByApartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { buildRelevantRemark, buildRemarksByType } from '#utils'

type Props = {
  house: House | null
  types: ApartmentsAcceptanceType[] | null
  remarksByApartment: RemarksByApartment[] | null
}

const useDataTable = (props: Props) => {
  const { isExpertRight } = useRightCurrentUser()
  const { types, house, remarksByApartment } = props
  const visibilityRemark = localStorage.getItem('visibilityRemark') ?? '0'
  if (types === null || house === null || remarksByApartment === null) {
    return {
      dataSource: []
    }
  }

  const remarksByType = buildRemarksByType(remarksByApartment)

  const dataSource: RemarkByApartment[] = types.reduce((acc, type) => {
    const { slug, id } = type
    const remarks = remarksByType[id] ?? []
    const relevantRemark = buildRelevantRemark(remarks)
    const hasTypeRemarks = isExpertRight && remarks.length === 0 && visibilityRemark === '0'

    if (hasTypeRemarks) {
      return acc
    }

    acc.push({
      // @ts-ignore
      [`id.options.cell-style`]: { padding: 0 },
      typeId: id,
      typesOfWork: slug,
      remarks,
      relevantRemark,
      created_at: relevantRemark?.created_at && new Date(relevantRemark?.created_at).toLocaleDateString(),
      remark: relevantRemark?.remark?.name,
      end_time: relevantRemark?.endTime,
      executor: relevantRemark?.user_id,
      user_specialist_id: relevantRemark?.user_specialist_id,
      is_mark: relevantRemark?.accepted,
      date_verify: relevantRemark?.date_verify,
      user_verify_id: relevantRemark?.user_verify_id
    })

    return acc
  }, [] as RemarkByApartment[])

  return {
    dataSource
  }
}

export { type RemarkByApartment, useDataTable }
