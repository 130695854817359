import { Paper, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Gauge, gaugeClasses } from '@mui/x-charts'
import { useEffect, useState } from 'react'

import { useGetRemarksByUserIdMutation } from '#api/house-api'

import { CpiTable } from '../../../home/components/cpi-analytics/components'
import { RemarkStatus } from '../../../home/types'

const CpiUser = () => {
  const [cpiRemarks, setCpiRemarks] = useState<RemarkStatus[]>([])

  const [getRemarksByUserId, { isLoading, isSuccess }] = useGetRemarksByUserIdMutation()

  useEffect(() => {
    getRemarksByUserId(null).then(({ data = [] }: any) => setCpiRemarks(data as unknown as RemarkStatus[]))
  }, [])

  const coefficient = cpiRemarks?.[0]?.coefficient ?? 0

  return (
    <Paper sx={{ p: 2 }} elevation={3}>
      <Typography variant="h5" gutterBottom>
        Ваш коэффициент эффективности
      </Typography>

      {isLoading && <Skeleton width={200} height={200} />}
      {isSuccess && (
        <Gauge
          sx={() => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 40
            },
            mb: 2
          })}
          width={200}
          height={200}
          value={coefficient * 100}
          text={() => String(coefficient)}
        />
      )}
      {isLoading && <Skeleton width={'100%'} height={140} />}
      {isSuccess && <CpiTable cpiRemarks={cpiRemarks} />}
    </Paper>
  )
}

export { CpiUser }
