import { RemarksByApartment } from '#api/types'
import { Table } from '#components'

import { useCell, useDataSidebarTable } from '../../hooks'

type Props = {
  remarks: RemarksByApartment[]
  houseId: number
}

const RemarksTable = ({ remarks }: Props) => {
  const { headCells } = useCell({ hasDetailTable: true })
  const { dataSource } = useDataSidebarTable(remarks)

  return (
    <>
      <Table
        isSelect={false}
        hasTableToolbar={false}
        propKey="end_time"
        defaultOrder="end_time"
        perPage={25}
        headCells={headCells}
        hasDetailPagination={true}
        tablePage={1}
        dataSource={dataSource as any[]}
      />
    </>
  )
}

export { RemarksTable }
