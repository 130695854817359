import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useHouseMutation, useRemarksByApartmentMutation } from '#api/house-api'
import { Apartment } from '#api/types'
import { useUsersMutation } from '#api/userApi'
import { LayoutPage, Table } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { getFullAddress, getNameByType } from '#utils'

import { type BreadcrumbsType } from '../../components/Breadcrumb'
import FullScreenLoader from '../../components/FullScreenLoader'
import { ApartmentDate, ApartmentTableHeader, RemarkPhotoSidebar, SidebarRemark } from './components'
import { Mode } from './components/sidebar-remark/sidebar-remark'
import {
  RemarkContext,
  RemarkContextProvider,
  RemarkNewContextProvider,
  RemarksAllViewContextProvider,
  SelectRemarkContext,
  SelectRemarkContextProvider
} from './contexts'
import { useBreadcrumbs, useCellApartment, useDataTable } from './hooks'

const ApartmentPage = () => {
  const { remarksByApartment, setParamsContext } = useContextSafe(RemarkContext)
  const { selectRemark } = useContextSafe(SelectRemarkContext)

  const house = useAppSelector((state) => state.houseState.house)
  const params = useParams()

  const { apartmentId, houseId } = params ?? { apartmentId: 0, houseId: 0 }
  const types = useAppSelector((state) => state.houseState.types)
  const [getUsers] = useUsersMutation()
  const [getHouse] = useHouseMutation()
  const [getRemarksByApartment, { isLoading }] = useRemarksByApartmentMutation()
  const [apartment, setApartment] = useState<Apartment | null>(null)
  const nameByType = getNameByType(apartment?.type ?? 1)

  useEffect(() => {
    const findApartment = (house?.apartments ?? []).find(({ id }) => id === Number(apartmentId))
    const findIndexApartment = (house?.apartments ?? []).findIndex(({ id }) => id === Number(apartmentId))

    if (findApartment) {
      setApartment(findApartment)

      localStorage.setItem('apartment_index', String(findIndexApartment))
    }
  }, [house])

  useEffect(() => {
    getUsers(null)
    getHouse(Number(houseId))
  }, [])

  useEffect(() => {
    setParamsContext({
      apartmentId: Number(params.apartmentId),
      houseId: Number(params.houseId)
    })
  }, [params.apartmentId, params.houseId])

  useEffect(() => {
    if (!apartment?.id) {
      return
    }

    getRemarksByApartment({
      apartmentId: apartment.id,
      houseId: Number(houseId)
    })
  }, [apartment])

  const headCells = useCellApartment(Number(houseId))

  const { dataSource } = useDataTable({ types, house, remarksByApartment })

  const fullAddress = getFullAddress(house)
  const title = `${nameByType} №${apartment?.number}`

  const breadcrumbs: BreadcrumbsType[] = useBreadcrumbs({ title, fullAddress, houseId })

  if (!house || !apartment || isLoading) {
    return <FullScreenLoader />
  }

  return (
    <LayoutPage breadcrumbs={breadcrumbs}>
      <>
        <ApartmentDate apartment={apartment} />

        <Table
          isSelect={false}
          hasTableToolbar={true}
          tableName={<ApartmentTableHeader apartment={apartment} title={title} fullAddress={fullAddress} />}
          propKey="typesOfWork"
          defaultOrder="typesOfWork"
          perPage={25}
          hasDetailPagination={true}
          tablePage={1}
          rowsPerPageOptions={[]}
          headCells={headCells}
          dataSource={dataSource}
        />

        <SidebarRemark mode={Mode.CREATE} remarksByApartment={remarksByApartment ?? []} apartment={apartment} />

        {selectRemark && (
          <RemarkPhotoSidebar houseId={Number(houseId)} open={Boolean(selectRemark)} remark={selectRemark} />
        )}
      </>
    </LayoutPage>
  )
}

const ApartmentPageWithContext = () => (
  <RemarkContextProvider>
    <RemarkNewContextProvider>
      <RemarksAllViewContextProvider>
        <SelectRemarkContextProvider>
          <ApartmentPage />
        </SelectRemarkContextProvider>
      </RemarksAllViewContextProvider>
    </RemarkNewContextProvider>
  </RemarkContextProvider>
)

export default ApartmentPageWithContext
