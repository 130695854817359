import { Apartment, RemarksByApartment } from '#api/types'
import { buildRelevantRemark, getDayDiff, getEndDate } from '#utils'

import { ExtentApartment } from '../../../config/constants'

enum StatusWork {
  DEFAULT = 'Default',
  DONE = 'Done',
  IN_WORK = 'InWork',
  DANGER = 'Danger'
}

type RemarksByStatus = {
  [key in StatusWork]: RemarksByApartment[]
}

const getRemarkStatus = (remarkByApartment: RemarksByApartment) => {
  const { created_at, period, remark = null, user_verify_id: userVerifyId } = remarkByApartment
  const completionDay = period ?? remark?.period ?? 0
  const status: StatusWork = StatusWork.DEFAULT

  if (userVerifyId) {
    return StatusWork.DONE
  }

  const currentDate = new Date()
  const endDate = getEndDate(created_at, completionDay)

  if (currentDate.getTime() > endDate.getTime()) {
    return StatusWork.DANGER
  }

  const dayToTheEnd = getDayDiff(currentDate, endDate)

  if (dayToTheEnd > 0) {
    return StatusWork.IN_WORK
  }

  return status
}

const getSortByStatus = (remarksByApartment: RemarksByApartment[]) => {
  const SortByStatus = remarksByApartment.reduce(
    (acc, remark) => {
      const status = getRemarkStatus(remark)

      acc[status].push(remark)

      return acc
    },
    {
      [StatusWork.DANGER]: [],
      [StatusWork.IN_WORK]: [],
      [StatusWork.DONE]: [],
      [StatusWork.DEFAULT]: []
    } as RemarksByStatus
  )

  return SortByStatus
}

const getExtentRange = (remarksByApartment: RemarksByApartment[], apartment: Apartment) => {
  const SortByStatus = getSortByStatus(remarksByApartment)

  if (
    !apartment.document &&
    (SortByStatus.Done.length !== 0 ||
      SortByStatus.Danger.length !== 0 ||
      SortByStatus.InWork.length !== 0 ||
      SortByStatus.Default.length !== 0)
  ) {
    return [ExtentApartment.NotAccepted]
  }

  if (
    SortByStatus.Done.length === remarksByApartment.length &&
    SortByStatus.Danger.length === 0 &&
    SortByStatus.InWork.length === 0 &&
    SortByStatus.Default.length === 0 &&
    apartment.document
  ) {
    return [ExtentApartment.Accepted]
  }

  if (SortByStatus.Danger.length) {
    return [ExtentApartment.NotAccepted, ExtentApartment.InWork]
  }

  if (SortByStatus.InWork.length && apartment.document) {
    return [ExtentApartment.NotAccepted, ExtentApartment.InWork, ExtentApartment.Accepted]
  }

  if (SortByStatus.InWork.length) {
    return [ExtentApartment.NotAccepted, ExtentApartment.InWork]
  }

  if (SortByStatus.Default.length && SortByStatus.Danger.length === 0 && SortByStatus.InWork.length === 0) {
    return [ExtentApartment.Default, ExtentApartment.InWork]
  }

  if (
    SortByStatus.Done.length === 0 &&
    SortByStatus.Danger.length === 0 &&
    SortByStatus.InWork.length === 0 &&
    SortByStatus.Default.length === 0 &&
    apartment.document
  ) {
    return [ExtentApartment.Accepted, ExtentApartment.InWork]
  }

  return [ExtentApartment.Default]
}

const getLastDateByDirector = (remarksByApartment: RemarksByApartment[]) => {
  const SortByStatus = getSortByStatus(remarksByApartment)

  if (SortByStatus.Danger.length) {
    return buildRelevantRemark(SortByStatus.Danger)
  }

  if (SortByStatus.InWork.length) {
    return buildRelevantRemark(SortByStatus.InWork)
  }

  return buildRelevantRemark([...SortByStatus.Done, ...SortByStatus.Default])
}

export { getLastDateByDirector, getExtentRange }
