import { IUser, type RemarksByApartment, type RemarksByType } from '#api/types'

import { VisibilityRemark } from '../../pages'

type Options = {
  isExpertRight: boolean
  user: IUser | null
  visibilityRemark: VisibilityRemark
}

const filterRemarks = (remarkByApartment: RemarksByApartment[], user: IUser | null) =>
  remarkByApartment.filter((remark) => remark.user_id === user?.id)

const buildGroupByApartment = (
  remarkByApartment: RemarksByApartment[],
  { isExpertRight, user, visibilityRemark }: Options
) => {
  const remarks = isExpertRight && visibilityRemark === '0' ? filterRemarks(remarkByApartment, user) : remarkByApartment

  return remarks.reduce((acc, item) => {
    const { apartment_id } = item

    return {
      ...acc,
      [apartment_id]: apartment_id in acc ? [...acc[apartment_id], item] : [item]
    }
  }, {} as RemarksByType)
}

export { buildGroupByApartment }
