import { useContextSafe } from '#hooks/use-context-safe'

import { HeadCell } from '../../../components/table/type'
import { RemarkCheckbox, RemarkCompletionDate, RemarkName, RemarkUserName } from '../components'
import { SelectRemarkContext } from '../contexts'
import { Style } from '../utils'
import { CheckboxMode } from '../utils/config'
import { type RemarkByApartment } from './use-data-table'

type Props = {
  hasDetailTable?: boolean
}

const useCell = ({ hasDetailTable = false }: Props = {}) => {
  const { setSelectRemark } = useContextSafe(SelectRemarkContext)

  const headCells: HeadCell<RemarkByApartment>[] = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: 'Id',
      style: { width: '3%' }
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Дата создания',
      align: 'left',
      style: { ...Style, width: '5%' }
    },
    {
      id: 'user_specialist_id',
      numeric: false,
      disablePadding: false,
      label: 'Специалист',
      align: 'left',
      style: { ...Style, width: '12.5%' },
      render: (specialistId: string, item) => <RemarkUserName isSpecialist={true} userId={specialistId} item={item} />
    },
    {
      id: 'remark',
      numeric: false,
      disablePadding: false,
      label: 'Замечания',
      align: 'left',
      style: { ...Style, width: '32.5%' },
      render: (remarkName: string, item) => {
        const props = {
          remarkName,
          item,
          hasDetailTable,
          setCurrentRemark: setSelectRemark
        }

        return <RemarkName {...props} />
      }
    },
    {
      id: 'end_time',
      numeric: false,
      disablePadding: false,
      label: 'Срок выполнения',
      align: 'left',
      style: { ...Style, width: '19.5%' },
      render: (_, item) => <RemarkCompletionDate item={item} />
    },
    {
      id: 'executor',
      numeric: false,
      disablePadding: false,
      label: 'Исполнитель',
      style: { ...Style, width: '10%' },
      align: 'left',
      render: (executorId: string, item) => <RemarkUserName userId={executorId} item={item} />
    },
    {
      id: 'is_mark',
      numeric: false,
      disablePadding: false,
      label: 'Отметка о выполнении',
      style: { ...Style, width: '5%' },
      align: 'center',
      render: (_, { relevantRemark }) => <RemarkCheckbox mode={CheckboxMode.EXECUTION} remark={relevantRemark} />
    },
    {
      id: 'date_verify',
      numeric: false,
      disablePadding: false,
      label: 'Дата проверки',
      align: 'center',
      style: { ...Style, width: '5%' },
      render: (dateVerify) => {
        if (!dateVerify) {
          return ''
        }

        return <b>{new Date(dateVerify).toLocaleDateString()}</b>
      }
    },
    {
      id: 'checked',
      numeric: false,
      disablePadding: false,
      label: 'Проверил',
      align: 'center',
      style: { ...Style, width: '5%' },
      render: (_, { relevantRemark }) => <RemarkCheckbox mode={CheckboxMode.VERIFY} remark={relevantRemark} />
    }
  ]

  return {
    headCells
  }
}

export { useCell }
