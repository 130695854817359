import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useApartmentsInfoByHomeMutation, useHouseMutation } from '#api/house-api'
import { House } from '#api/types'
import { LayoutPage, Table } from '#components'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'
import { buildGroupByApartment, getFullAddress } from '#utils'

import FullScreenLoader from '../../components/FullScreenLoader'
import { setPageApartment } from '../../redux/features/apartmentsPageSlice'
import { ApartmentsTableHeader } from './components'
import { useBreadcrumbs, useDataTable } from './hooks'

enum VisibilityRemark {
  ALL = '1',
  EXPERT = '0'
}

const ApartmentsPage = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [visibilityRemark, setVisibilityRemark] = useState<VisibilityRemark>(
    (localStorage.getItem('visibilityRemark') as VisibilityRemark) ?? VisibilityRemark.EXPERT
  )
  const pageByHome = useAppSelector((state) => state.apartmentsPageState.pageByHome)
  const types = useAppSelector((state) => state.houseState.types)
  const apartmentsInfoByHome = useAppSelector((state) => state.houseState.remarksApartmentsByHome)
  const [house, setHouse] = useState<House | null>(null)
  const [getHouse] = useHouseMutation()
  const { isExpertRight, user } = useRightCurrentUser()

  const [getApartmentsInfoByHome] = useApartmentsInfoByHomeMutation()
  const { houseId } = params ?? { houseId: 0 }
  const fullAddress = getFullAddress(house)
  const pageMemo = pageByHome?.[fullAddress] ?? 1
  useEffect(() => {
    const elem = document.querySelector(`#enhanced-table-checkbox-${pageMemo - 1}`)

    elem?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  }, [pageMemo])

  const changeVisibilityRemark = () => {
    const value = visibilityRemark === VisibilityRemark.ALL ? VisibilityRemark.EXPERT : VisibilityRemark.ALL
    setVisibilityRemark(value)
    localStorage.setItem('visibilityRemark', value)
  }

  const callbackSaveApartment = (numberApartment: number) => {
    const newPage = {
      ...pageByHome,
      [fullAddress]: numberApartment
    }

    dispatch(setPageApartment(newPage))
  }

  useEffect(() => {
    getApartmentsInfoByHome(Number(houseId))
    getHouse(Number(houseId)).then((data) => {
      if ('data' in data) {
        setHouse(data.data)
      }
    })
  }, [])

  const breadcrumbs = useBreadcrumbs({ fullAddress })

  const groupByApartment = buildGroupByApartment(apartmentsInfoByHome, { isExpertRight, user, visibilityRemark })

  const { headCells, dataSource } = useDataTable({
    house,
    types,
    groupByApartment,
    callbackSaveApartment
  })

  if (!house) {
    return <FullScreenLoader />
  }

  return (
    <LayoutPage breadcrumbs={breadcrumbs}>
      <Table
        isSelect={false}
        hasTableToolbar={true}
        tableName={
          <ApartmentsTableHeader
            visibilityRemark={visibilityRemark}
            changeVisibility={changeVisibilityRemark}
            fullAddress={fullAddress}
            houseId={house.id}
          />
        }
        propKey="id"
        defaultOrder="id"
        perPage={200}
        rowsPerPageOptions={[]}
        headCells={headCells}
        hasDetailPagination={true}
        dataSource={dataSource as any[]}
        tablePage={1}
      />
    </LayoutPage>
  )
}

export { VisibilityRemark }

export default ApartmentsPage
