import { zodResolver } from '@hookform/resolvers/zod'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Drawer, IconButton } from '@mui/material/'
import { useEffect, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useHousesMutation, useUpdateHouseMutation } from '#api/house-api'
import { FormInput } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { UpdateContext } from '../../context'
import { DrawerHeader } from '../../style'
import { type UpdateHouseInput, updateHouseSchema } from '../../types'

const UpdateHouseSidebar = () => {
  const { updateHouse, setUpdateHouse } = useContextSafe(UpdateContext)
  const [getHouses] = useHousesMutation()
  const [updateHouseMethod, { isLoading, isSuccess }] = useUpdateHouseMutation()

  const methods = useForm<UpdateHouseInput>({
    resolver: zodResolver(updateHouseSchema),
    defaultValues: useMemo(() => {
      if (updateHouse) {
        return updateHouse
      }

      return {}
    }, [updateHouse])
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSuccess) {
      toast.success('Дом успешно обновлен')
    }
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful])

  const closeUpdateSidebar = () => {
    setUpdateHouse(null)
  }

  const onSubmitHandler: SubmitHandler<UpdateHouseInput> = async (values) => {
    await updateHouseMethod(values)
    setUpdateHouse(null)
    getHouses(null)
  }

  const DrawerList = (
    <Box sx={{ width: '600px' }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            p: { xs: '1rem', sm: '2rem' }
          }}
        >
          <FormInput name="street" label="Улица *" />
          <FormInput name="number" type="number" label="Номер *" />
          <FormInput name="drop" label="Корпус" />
          <FormInput name="developer" label="Застройщик *" />
          <FormInput name="director" label="Директор *" />

          <LoadingButton
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            disableElevation
            type="submit"
            loading={isLoading}
          >
            Обновить
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  )

  return (
    <Drawer open={updateHouse !== null} role="presentation" variant="persistent" anchor="right">
      <DrawerHeader>
        <IconButton onClick={closeUpdateSidebar}>
          <Close />
        </IconButton>
      </DrawerHeader>

      {DrawerList}
    </Drawer>
  )
}

export { UpdateHouseSidebar }
